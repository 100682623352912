import React from "react";
import { Content } from './content'
import { MoleculesIllustration } from "components/library/illustrations/molecules-illustration";
import { Slideshow } from "./slideshow/slideshow";

export const ExploreBenefits = () => {
    return (
        <section className='flex flex-col gap-12 sm:gap-24 md:p-0 pb-4 md:pb-10 items-center relative'>
            <div className='absolute right-0 bottom-0 md:top-0 w-full md:w-1/2 z-[-1] h-1/2 md:h-full bg-gradient-to-b from-[#A6BFF7] to-[#FAD8BD]'></div>

            <MoleculesIllustration className='hidden sm:block absolute -left-60 -bottom-20 w-[480px] z-[-1] transform -rotate-45' />

            <Content />
            <Slideshow />
        </section>
    )
};
