import React, { useMemo, useState } from "react"
import { Card, CardProps } from "./card"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Tabs } from "../tabs"
import { ChevronLeft, ChevronRight } from "react-feather"
import {
	ProjectManagementIllustration
} from "components/library/illustrations/explore-benefits/connect-everything-illustration";
import { UploadDataIllustration } from "components/library/illustrations/explore-benefits/upload-data-illustration";
import {
	DataFormulationsIllustration
} from "components/library/illustrations/explore-benefits/explore-data-illustration";
import { TeamManagementIllustration } from "components/library/illustrations/explore-benefits/team-collaboration";
const benefits: CardProps[] = [
	{
		heading: "connect-page.explore-benefits.slideshow.1.heading",
		desc: "connect-page.explore-benefits.slideshow.1.desc",
		screenshot: ProjectManagementIllustration,
	},
	{
		heading: "connect-page.explore-benefits.slideshow.2.heading",
		desc: "connect-page.explore-benefits.slideshow.2.desc",
		screenshot: UploadDataIllustration,
	},
	{
		heading: "connect-page.explore-benefits.slideshow.3.heading",
		desc: "connect-page.explore-benefits.slideshow.3.desc",
		screenshot: DataFormulationsIllustration,
	},
	{
		heading: "connect-page.explore-benefits.slideshow.4.heading",
		desc: "connect-page.explore-benefits.slideshow.4.desc",
		screenshot: TeamManagementIllustration,
	},
]

export const Slideshow = () => {
	const [slide, setSlide] = useState(0)

	const slides = useMemo(() => {
		return benefits.map((benefit) => (
			<Card key={benefit.heading} {...benefit} />
		))
	}, [])

	return (
		<section className="flex flex-col gap-4 sm:gap-6 w-full md:w-[95%]">
			<Tabs slide={slide} setSlide={setSlide} />

			<div className="flex flex-col gap-8 w-full relative">
				{/* <button
					onClick={() => setSlide(slide - 1)}
					disabled={slide === 0}
					className="h-full absolute disabled:text-gray-400 disabled:cursor-not-allowed flex items-center left-[2%] sm:left-[5%] z-[10]"
				>
					<ChevronLeft size={24} />
				</button> */}

				<Carousel
					selectedItem={slide}
					showArrows={false}
					showStatus={false}
					infiniteLoop={true}
					showIndicators={false}
					interval={7000}
					autoPlay={true}
					onChange={setSlide}>
					{slides.map(s => s)}
				</Carousel>

				{/* <button
					onClick={() => setSlide(slide + 1)}
					disabled={slide === benefits.length - 1}
					className="h-full absolute flex disabled:text-gray-400 disabled:cursor-not-allowed items-center right-[2%] sm:right-[5%] z-[10]"
				>
					<ChevronRight size={24} />
				</button> */}
			</div>
		</section>
	)
}
