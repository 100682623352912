import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<"svg">

export const DataIngestionIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 66 62"
            className={mergeClasses({ classes: ['w-20', className] })}
            {...props}
        >
            <path
                fill="url(#paint0_linear_5569_40223)"
                fillRule="evenodd"
                d="M49.616 0h-37.19v49.236h37.19V9.166L40.45 0h9.166z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint1_linear_5569_40223)"
                d="M40.45 9.167V.001l9.166 9.166H40.45z"
            ></path>
            <path
                fill="#AADABD"
                fillRule="evenodd"
                d="M31.058 17.91a6.522 6.522 0 100 13.044 6.522 6.522 0 000-13.044zm-8.696 6.522a8.696 8.696 0 1117.392 0 8.696 8.696 0 01-17.392 0zm13.176-3.181a1.087 1.087 0 010 1.537l-4.671 4.672a1.83 1.83 0 01-2.587 0l-1.517-1.517a1.087 1.087 0 111.537-1.537l1.273 1.273 4.428-4.428a1.087 1.087 0 011.537 0zm-5.721 4.671z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint2_linear_5569_40223)"
                fillRule="evenodd"
                d="M65.234 19.655l-8.69-8.69H29.97v46.688h35.264V19.655z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint3_linear_5569_40223)"
                d="M56.542 19.657v-8.693l8.692 8.693h-8.692z"
            ></path>
            <path
                fill="#AADABD"
                fillRule="evenodd"
                d="M47.622 29.09a5.28 5.28 0 100 10.56 5.28 5.28 0 000-10.56zm-7.04 5.28a7.04 7.04 0 1114.08 0 7.04 7.04 0 01-14.08 0zm10.667-2.575a.88.88 0 010 1.244l-3.782 3.782a1.48 1.48 0 01-2.094 0l-1.228-1.227a.88.88 0 011.244-1.245l1.03 1.03 3.585-3.584a.88.88 0 011.245 0zm-4.632 3.782z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint4_linear_5569_40223)"
                fillRule="evenodd"
                d="M40.264 17.964L30.34 8.041H0v53.307h40.264V17.964z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint5_linear_5569_40223)"
                d="M30.34 17.965V8.041l9.925 9.924H30.34z"
            ></path>
            <path
                fill="#AADABD"
                fillRule="evenodd"
                d="M19.463 26.813a8.386 8.386 0 100 16.771 8.386 8.386 0 000-16.77zM8.282 35.2c0-6.175 5.006-11.18 11.18-11.18 6.176 0 11.182 5.005 11.182 11.18s-5.006 11.18-11.181 11.18c-6.175 0-11.181-5.005-11.181-11.18zm16.941-4.09c.546.545.546 1.43 0 1.976l-6.006 6.006a2.352 2.352 0 01-3.326 0l-1.95-1.95a1.398 1.398 0 111.976-1.976l1.637 1.637 5.693-5.693a1.398 1.398 0 011.976 0zm-7.356 6.006z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_5569_40223"
                    x1="31.021"
                    x2="31.021"
                    y1="0"
                    y2="49.236"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5569_40223"
                    x1="51.495"
                    x2="37.032"
                    y1="2.077"
                    y2="17.682"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="0.59" stopColor="#9CF1BE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5569_40223"
                    x1="47.602"
                    x2="47.602"
                    y1="10.964"
                    y2="57.653"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5569_40223"
                    x1="67.016"
                    x2="53.302"
                    y1="12.934"
                    y2="27.731"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FCEADC"></stop>
                    <stop offset="0.59" stopColor="#FCEADC" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_5569_40223"
                    x1="20.132"
                    x2="20.132"
                    y1="8.041"
                    y2="61.348"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3C67F0"></stop>
                    <stop offset="1" stopColor="#3C67F0" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_5569_40223"
                    x1="42.299"
                    x2="26.64"
                    y1="10.29"
                    y2="27.185"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DEE9FD"></stop>
                    <stop offset="0.59" stopColor="#DEE9FD" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
