import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"
import { Illustration } from "types/components"

type P = {
	heading: TranslationKey
	desc: TranslationKey
	screenshot: Illustration
}

export type CardProps = P

export const Card = ({ desc, heading, screenshot }: P) => {
	const { t } = useTranslation();
	const Screenshot = screenshot;

	return (
		<div className="p-6 sm:p-10 bg-[#F3F6FA] flex flex-col gap-4 w-[95%] sm:gap-0 h-full">
			<div className="flex flex-col gap-2 md:gap-4 md:col-span-2">
				<h3 className="text-left text-xl sm:text-2xl md:text-4xl">
					{t(heading) as string}
				</h3>
				<p className="text-left sm:text-lg max-w-3xl">
					<Trans
						i18nKey={(desc) as string}
						components={{
							bold: <span className="text-theme-orange" />,
						}}
					/>
				</p>
			</div>

			<Screenshot />
		</div>
	)
}
