import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<"svg">

export const AnalyticsIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 72 67"
            className={mergeClasses({ classes: ['w-20', className] })}
            {...props}
        >
            <path
                fill="url(#paint0_linear_5569_40263)"
                d="M4 43.947a2 2 0 012-2h16.579a2 2 0 012 2V67H4V43.947z"
            ></path>
            <path
                fill="url(#paint1_linear_5569_40263)"
                d="M26.368 30.526a2 2 0 012-2h18.369a2 2 0 012 2V67H26.368V30.526z"
            ></path>
            <path
                fill="url(#paint2_linear_5569_40263)"
                d="M50.526 14.42a2 2 0 012-2h16.58a2 2 0 012 2V67h-20.58V14.42z"
            ></path>
            <path
                fill="url(#paint3_linear_5569_40263)"
                d="M44.794 3.238L37.68 8.175l7.833 3.694-.718-8.63zM1.113 37.626c10.003-1.522 30.91-9.17 41.456-27.915l-1.307-.735C31.029 27.166 10.64 34.659.887 36.143l.226 1.483z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_5569_40263"
                    x1="14.29"
                    x2="14.29"
                    y1="41.947"
                    y2="67"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5569_40263"
                    x1="37.553"
                    x2="37.553"
                    y1="28.526"
                    y2="67"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5569_40263"
                    x1="60.816"
                    x2="60.816"
                    y1="12.421"
                    y2="67"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1753E2"></stop>
                    <stop offset="1" stopColor="#5F85EB" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5569_40263"
                    x1="24.358"
                    x2="21.436"
                    y1="1.619"
                    y2="38.504"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3C67F0"></stop>
                    <stop offset="1" stopColor="#DEE9FD" stopOpacity="0.46"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
