import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

const tabs: TranslationKey[] = [
    'connect-page.explore-benefits.tab.1',
    'connect-page.explore-benefits.tab.2',
    'connect-page.explore-benefits.tab.3',
    'connect-page.explore-benefits.tab.4',
]

type P = {
    slide: number
    setSlide: Dispatch<SetStateAction<number>>
};

export const Tabs = ({ setSlide, slide }: P) => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col md:flex-row justify-between gap-4 sm:gap-8 w-[95%]">
            {tabs.map((tab, i) => {
                const active = i === slide
                return (
                    <div
                        key={tab}
                        onClick={() => setSlide(i)}
                        className={`w-full p-4 cursor-pointer bg-[#F3F6FA] ${active ? "text-theme-blue border-b-4 border-theme-blue font-semibold" : ""}`}
                    >
                        {t(tab) as string}
                    </div>
                )
            })}
        </div>
    );
};
