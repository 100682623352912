import React, {
	ChangeEvent,
	Dispatch,
	FormEvent,
	Fragment,
	SetStateAction,
	useEffect,
	useState,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Input, PrimaryBtn } from "components/library"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { connectTrialRegistration } from "services"
import Success from "images/connect/success.svg"
import { EMAIL_PATTERN } from "utils/validation"
import { FailureIllustration } from "components/library/illustrations/failure";

type P = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

const InitialData = {
	email: "",
	organization: "",
	job_title: "",
}

export type Status = "initial" | "success" | "error"

export const EnquiryRegisterForm = ({ open, setOpen }: P) => {
	const [data, setData] = useState(InitialData)
	const [isEmailInvalid, setIsEmailInvalid] = useState(false)
	const [status, setStatus] = useState<Status>("initial")
	const [message, setMessage] = useState<string | null>(null)
	const { t } = useTranslation()

	useEffect(() => {
		document.body.style.overflow = open ? "hidden" : "initial"
	}, [open])

	function handleDataChange(e: ChangeEvent<HTMLInputElement>) {
		const { name, value } = e.target

		if (name === "email") setIsEmailInvalid(false)

		setData({ ...data, [name]: value })
	}

	async function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault()

		if (!EMAIL_PATTERN.test(data.email)) return setIsEmailInvalid(true)

		try {
			const res = await connectTrialRegistration(data)

			if (res.result.status === "Failed") {
				setStatus("error")
				setMessage(res.result.message)
				return
			}

			if (res.result.message === "You have already asked for enquiry") {
				setStatus("success")
				setMessage(t("connect-page.form.success.already-registered"))
				return
			}

			setStatus("initial")
			location.href = "/connect/thank-you/"
		} catch (err) {
			setStatus("error")
			setMessage(t("common.messages.internal-server-error"))
		} finally {
			setData(InitialData)
		}
	}

	function close() {
		setOpen(false)
	}

	useEffect(() => {
		if (open) setStatus("initial")
	}, [open])

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-50 inset-0 overflow-y-auto"
				onClose={close}
			>
				<div className="flex relative items-center p-6 justify-center min-h-screen text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<Transition.Child
						as="div"
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						{status === "initial" && (
							<div className="bg-gray-50 rounded-lg text-left shadow-xl sm:max-w-lg w-full p-16">
								<form
									onSubmit={handleFormSubmit}
									name="Case Study Download Form"
									className="flex flex-col gap-6"
								>
									<Dialog.Title
										as="h3"
										className="text-4xl font-medium text-gray-900 text-center"
									>
										{t("connect-page.form.heading")}
									</Dialog.Title>
									<div className="flex flex-col gap-3">
										<div className="flex flex-col gap-2">
											<Input
												type="email"
												name="email"
												value={data.email}
												isError={isEmailInvalid}
												onChange={handleDataChange}
												placeholder={`${t(
													"download-document.input-placeholder"
												)}*`}
												required
											/>
											{isEmailInvalid && (
												<span className="text-red-400 text-sm">
													{t("download-document.wrong-email")}
												</span>
											)}
										</div>
										<div>
											<Input
												name="organization"
												className="w-full"
												value={data.organization}
												onChange={handleDataChange}
												placeholder={`${t("common.words.organization")}*`}
												required
											/>
										</div>
										<div>
											<Input
												name="job_title"
												className="w-full"
												value={data.job_title}
												onChange={handleDataChange}
												placeholder={`${t("common.words.job-title")}*`}
												required
											/>
										</div>
									</div>

									<PrimaryBtn type="submit">Register</PrimaryBtn>

									<div className="text-xs text-center">
										<Trans
											i18nKey="common.messages.agree-to-tou-n-policy"
											components={{
												privacypolicy: (
													<Link to="/privacy-policy/" className="font-medium" />
												),
												termsofuse: (
													<Link to="/terms-of-use/" className="font-medium" />
												),
											}}
										/>
									</div>
								</form>
							</div>
						)}

						{status !== "initial" && (
							<div className="bg-gray-50 rounded-lg text-left shadow-xl sm:max-w-lg w-full p-16">
								<div className="flex flex-col gap-6 items-center text-center">
									{status === "success" ? (
										<img src={Success} alt="rocket launch" className="w-32" />
									) : (
										<FailureIllustration className="w-60" />
									)}
									<h3
										className={`text-3xl sm:text-4xl ${
											status === "success"
												? "text-theme-blue"
												: "text-theme-orange"
										} max-w-xl`}
									>
										{status === "success"
											? t("connect-page.form.success.heading")
											: message}
									</h3>
									{status === "success" && (
										<p className="text-xl sm:text-2xl max-w-xl">{message}</p>
									)}
									<PrimaryBtn onClick={close}>
										{t("common.cta.okay")}
									</PrimaryBtn>
								</div>
							</div>
						)}
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
