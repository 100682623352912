import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from "utils"

type P = ComponentPropsWithoutRef<"svg">

export const FailureIllustration = ({ className = "", ...props }: P) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 214 164"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<g clipPath="url(#clip0_8266_42680)">
				<path
					fill="#EBF3FA"
					d="M198.745 151.815c-13.127 0-190.592-.382-190.592-.409-.07-.169-22.525-27.757 5.749-56.606 14.013-14.295-12.861-32.809 0-48.703C35.94 18.864 57.748 34.2 75.915 26.545c3.206-1.35 6.43-3.525 9.655-6.864a36.081 36.081 0 015.934-4.937c.328-.221.656-.435.993-.639a40.594 40.594 0 013.959-2.158 37.34 37.34 0 012.799-1.198 45.51 45.51 0 014.633-1.51 51.93 51.93 0 016.909-1.358 59.281 59.281 0 013.109-.32c20.302-1.527 43.137 7.077 54.926 19.65 2.374 2.53 13.163 15.636 4.66 33.644-5.368 11.374 17.13 22.234 25.129 35.215 20.32 33.005.31 55.31.124 55.745z"
				></path>
				<g opacity="0.8">
					<path
						fill="#D7E5FF"
						d="M152.818 75.062a25.455 25.455 0 01-1.984 4.235 25.708 25.708 0 01-7.211 7.93c-6.687 4.812-15.554 6.34-23.871 3.302-9.894-3.605-16.254-12.653-16.936-22.553-.089-1.305-.08-2.62.035-3.942a26.054 26.054 0 013.889-11.623c.009-.018.018-.036.035-.054a25.715 25.715 0 0110.86-9.563 25.425 25.425 0 013.853-1.447 25.673 25.673 0 0115.9.56c.62.221 1.222.47 1.807.736.832.373 1.629.79 2.4 1.243a25.638 25.638 0 018.052 7.415 25.764 25.764 0 013.126 5.975c.408 1.11.744 2.256 1.001 3.41.16.72.284 1.456.381 2.193a25.52 25.52 0 01.133 5.399 26.001 26.001 0 01-1.47 6.784z"
					></path>
					<path
						fill="#AEC3FF"
						d="M124.269 52.34c-1.523 1.18-1.116 4.35-3.755 2.566-2.64-1.785-4.296-2.753-5.041-1.2-.752 1.564 1.595 2.416.975 4.112-.62 1.696-1.869 1.874-3.189.48-1.32-1.395-1.355-2.735-3.499-2.69-2.134.044-3.056-.836-2.949-2.452a2.2 2.2 0 00-.071-.745c.009-.018.017-.036.035-.054a25.714 25.714 0 0110.86-9.563 25.425 25.425 0 013.853-1.447 27.431 27.431 0 011.913-.47c.744.301 1.541.923 1.967 2.157.93 2.717 2.444 2.078 1.16 4.36-1.284 2.282-.753 2.841.647 3.258 1.399.418 3.011 1.004 2.79 2.114-.222 1.11-4.172-1.616-5.696-.426zM152.818 75.062a25.45 25.45 0 01-1.984 4.235 25.708 25.708 0 01-7.211 7.93c-.717-.027-1.373-.267-1.922-.755-2.586-2.318.842-4.777 2.64-5.967 1.807-1.199-1.541-1.59-3.233-3.925-1.692-2.335 2.267-5.487-.275-5.656-2.542-.169-5.633-.337-6.147-3.063-.514-2.726 2.152-2.167 1.683-4.467-.47-2.3 1.745-2.726 4.11-2.069s2.657-1.092 5.589-.301c2.932.79 1.55 4.413 4.783 5.31 3.233.905 2.826 1.58 2.826 1.58.23.08.434.204.602.382a25.646 25.646 0 01-1.461 6.766zM154.155 62.88c-3.074.319-2.87-3.526-5.483-3.304-2.631.231-1.506-1.651-2.808-3.587-1.302-1.936-5.704-.888-4.809-2.85.894-1.963 3.348-.427 4.499-1.838 1.152-1.412 3.588-.063 3.588-.063.168 0 .336.018.505.063a25.762 25.762 0 013.126 5.975c.408 1.11.744 2.256 1.001 3.41.16.728.284 1.456.381 2.193zM134.65 47.882s2.091-1.483.124-2.859c-1.966-1.376-2.205-.595-3.826-1.394-1.63-.8-2.773.275-2.02 2.175.753 1.9.78 5.026 2.463 3.978 1.674-1.056 1.86-2.246 3.259-1.9z"
					></path>
				</g>
				<path
					fill="#7599EA"
					d="M86.252 95.724l-4.411 52.548s-.46 3.187 1.904 3.187c3.889.009 19.345 0 19.345 0s-2.923-.666-2.887-2.157c.044-1.758 5.155-54.084 5.155-54.084l-19.106.506z"
				></path>
				<path
					fill="#90AFF4"
					d="M104.543 150.527c-.762 0-15.731.027-19.514-.018-1.647-.017-1.94-1.474-1.948-2.406-.009-.453.053-.781.053-.781l.115-1.368.292-3.48.257-3.073.328-3.951.283-3.339 3.136-37.337 19.097-.497s-2.152 21.896-3.676 37.834c-.168 1.776-.327 3.481-.478 5.071-.044.506-.097 1.003-.142 1.482-.141 1.51-.274 2.895-.381 4.12-.062.649-.115 1.244-.159 1.794-.195 2.149-.31 3.525-.31 3.783 0 .151.009.293.035.426.302 1.669 2.729 1.74 3.012 1.74z"
				></path>
				<path
					fill="#AEC3FF"
					d="M39.873 67.124l10.735 43.242 1.47 5.922 5.005 20.139h92.918l-18.433-69.303H39.873z"
				></path>
				<path
					fill="#90AFF4"
					d="M39.873 67.124l-1.497.497 16.723 70.315 1.984-1.509-17.21-69.303z"
				></path>
				<path
					fill="#7599EA"
					d="M55.1 137.936h92.35l2.551-1.509H57.083l-1.984 1.509z"
				></path>
				<path fill="#AEC3FF" d="M87.538 151.482h19.992v-.95H87.538v.95z"></path>
				<path
					fill="#5888DD"
					d="M102.444 137.936l-.106.728-18.805 3.81.257-3.082.123-1.456h18.531z"
				></path>
				<path
					fill="#5888DD"
					d="M104.543 150.527c-.762 0-15.731.027-19.514-.018-1.647-.017-1.94-1.474-1.948-2.406l18.716-3.534c-.195 2.149-.31 3.525-.31 3.783 0 .151.009.293.036.426.31 1.678 2.737 1.749 3.02 1.749z"
					opacity="0.4"
				></path>
				<path
					fill="#AEC3FF"
					d="M39.873 67.124h91.695l14.872 55.7H53.7l-13.827-55.7z"
				></path>
				<path
					fill="#739AF0"
					d="M142.118 120.408H56.26l-3.065-12.253-1.187-4.733-.487-1.935-3.154-12.591-.159-.657-1.665-6.66-.753-2.974-.63-2.54-1.496-5.958h84.618l1.638 5.958.7 2.54.824 2.974 5.394 19.623.452 1.661.213.772 3.729 13.541.886 3.232z"
				></path>
				<path
					fill="#607CF4"
					d="M129.92 76.065H45.16l-1.496-5.958h84.618l1.638 5.958z"
				></path>
				<path
					fill="#E9F3FE"
					d="M47.172 72.797c-.107-.408.15-.745.558-.745.407 0 .823.337.93.745.106.409-.15.746-.558.746-.417 0-.833-.328-.93-.746zM49.634 72.798c-.106-.409.15-.746.558-.746.407 0 .824.337.93.746.106.408-.15.745-.558.745-.407 0-.824-.328-.93-.745zM52.291 72.798c-.106-.409.15-.746.558-.746.408 0 .824.337.93.746.107.408-.15.745-.558.745-.407 0-.823-.328-.93-.745z"
				></path>
				<path
					fill="#F77D48"
					d="M111.452 35.486c3.472-4.084 8.822-12.288 12.428-16.488.744-.862.124-2.203-1.01-2.203h-6.555l6.183-14.02c.265-.595-.549-1.074-.939-.55l-14.961 20.067c-.434.586.044 1.412.762 1.323l7.423-.915-4.412 12.156c-.239.666.621 1.172 1.081.63z"
				></path>
				<path
					fill="#AEC3FF"
					d="M80.592 60.366c.78-4.013 1.275-11.472 2.1-15.61a1.016 1.016 0 00-1.498-1.083l-4.358 2.46L75.711 34.5c-.044-.497-.762-.506-.832-.018l-2.445 18.93c-.071.551.549.924 1 .587l4.59-3.392 1.602 9.732c.098.55.86.56.966.026z"
				></path>
				<path
					fill="#E6EFFF"
					d="M145.873 121.598l-74.6 6.305c-5.668.479-9.76-5.31-7.44-10.514l31.604-70.91c2.392-5.38 9.762-6.002 13.021-1.1l42.996 64.605c3.153 4.742.079 11.135-5.581 11.614z"
				></path>
				<path
					fill="#F77D48"
					d="M146.103 124.422l-74.6 6.304c-3.666.311-7.13-1.305-9.264-4.315-2.135-3.01-2.507-6.819-1.001-10.185l31.604-70.91c1.55-3.48 4.748-5.754 8.539-6.074 3.791-.32 7.316 1.386 9.433 4.556l42.996 64.606c2.046 3.072 2.312 6.89.708 10.211-1.594 3.33-4.739 5.496-8.415 5.807zm-44.244-79.523a4.568 4.568 0 00-3.844 2.734L66.41 118.544a4.618 4.618 0 00.451 4.581c.957 1.359 2.516 2.078 4.172 1.945l74.6-6.304a4.627 4.627 0 003.791-2.611 4.62 4.62 0 00-.319-4.599L106.111 46.95a4.59 4.59 0 00-4.252-2.051z"
				></path>
				<path
					fill="#F77D48"
					d="M100.105 110.748c-.079-.968.018-1.865.293-2.717a6.258 6.258 0 011.275-2.247 6.721 6.721 0 012.144-1.589c.85-.409 1.833-.666 2.932-.755 1.107-.089 2.117-.009 3.038.249a6.652 6.652 0 012.4 1.207 6.258 6.258 0 011.63 1.998c.408.79.656 1.669.735 2.628.08.968-.017 1.865-.292 2.691a6.385 6.385 0 01-1.275 2.229c-.576.648-1.303 1.18-2.171 1.598-.868.408-1.851.666-2.958.755-1.107.097-2.108.008-3.021-.249a6.718 6.718 0 01-2.382-1.208 6.28 6.28 0 01-1.63-1.98c-.39-.781-.629-1.642-.718-2.61zm11.542-13.532l-10.744.905-4.615-36.68 13.791-1.163 1.568 36.938z"
				></path>
				<path
					fill="#233862"
					fillOpacity="0.1"
					d="M206.504 152.143H1.147a.257.257 0 01-.257-.257v-.151c0-.142.115-.258.257-.258h205.348c.142 0 .257.116.257.258v.151a.244.244 0 01-.248.257z"
				></path>
				<path
					fill="#C1C7D0"
					fillRule="evenodd"
					d="M193.627 89.837c1.208-.824 2.183-1.97 2.473-3.47.835-4.318-5.368-4.981-7.099-3.152-1.731 1.829-3.048 7.292-1.653 8.076.556.312 1.927.28 3.428-.147l-2.338 5.478 4.796 1.285.393-8.07z"
					clipRule="evenodd"
				></path>
				<path
					fill="#191847"
					fillRule="evenodd"
					d="M191.52 81.393c.708-.022 1.678.165 2.066.318a3.509 3.509 0 01.219-1.795c.224-.55.611-.967.985-1.412.172-.205.358-.404.592-.534.237-.132.508-.189.774-.18.271.01.538.087.78.21.107.053.195.132.299.19.106.06.18.004.282-.045.245-.117.56-.124.82-.086a2 2 0 01.819.327c.239.16.452.373.585.636.059.118.107.246.125.378.008.054.003.084.048.115.04.028.093.043.136.072.412.273.706.723.798 1.225.053.293-.011.766-.164 1.274-.154.508-.979 1.72-1.546 2.091-.507.331-1.157.465-1.744.443.256.514.361 1.1.4 1.676a7.334 7.334 0 01-.114 1.875c-.112.59-.272 1.201-.56 1.73-.269.494-.658.854-1.188 1.007-.243.07-.493.104-.742.13a4.538 4.538 0 01-.738.034c-.466-.028-.971-.18-1.218-.62a2.522 2.522 0 01-.23-.53c-.017-.063-.027-.105.027-.148.064-.052.153-.081.227-.116.42-.196.735-.524.911-.967.179-.45.214-1.082-.187-1.406-.495-.4-1.19-.28-1.761-.146-.014-.799-.27-1.584-.862-2.108-.655-.579-1.51-.855-2.2-1.387-.18-.138-.361-.289-.464-.5a.732.732 0 01-.09-.303c0-.133.109-.216.198-.296.47-.426 2.008-1.13 2.717-1.152z"
					clipRule="evenodd"
				></path>
				<path
					fill="#305CBD"
					fillRule="evenodd"
					d="M192.14 114.608l-7.501 26.22-5.472 19.325h-3.992l5.816-45.545h11.149z"
					clipRule="evenodd"
				></path>
				<path
					fill="#1753E2"
					fillRule="evenodd"
					d="M194.146 114.608c.173 13.514.765 20.885.947 22.112.182 1.228 2.936 9.039 8.263 23.433h-4.135c-6.781-13.809-10.566-21.619-11.356-23.433-.79-1.813-3.226-9.184-6.897-22.112h13.178z"
					clipRule="evenodd"
				></path>
				<path
					fill="#0B1730"
					fillRule="evenodd"
					d="M203.725 163.894l-.206-4.344h-4.345c-2.06 1.448-4.75 2.62-8.069 3.517v.827h7.862l2.69-.413v.413h2.068zM179.726 163.894l-.206-4.344h-4.345c-2.06 1.448-4.75 2.62-8.069 3.517v.827h7.862l2.69-.413v.413h2.068z"
					clipRule="evenodd"
				></path>
				<path
					fill="#C1C7D0"
					fillRule="evenodd"
					d="M175.276 109.146l-7.065 4.104c-1.83.29-3.379.728-4.648 1.314-.309.226-.704.708.475.742 1.18.033 2.419.122 2.539.536.119.414-.638.814-.368 1.385.18.38 1.302-.408 3.365-2.362l6.779-1.997-1.077-3.722zm28.246 2.665l-3.7-.114c2.398 9.045 3.695 13.834 3.89 14.366.438 1.197-.636 2.958-1.102 3.909 1.389.678 1.318-1.653 3.068-.766 1.598.809 2.786 2.215 4.701 1.13.236-.133.507-.657-.09-1.096-1.485-1.092-3.615-2.987-3.891-3.595-.376-.83-1.334-5.441-2.876-13.834z"
					clipRule="evenodd"
				></path>
				<path
					fill="#C1C7D0"
					fillRule="evenodd"
					d="M191.021 94.514l-2.106-1.13c-4.363 7.005-10.173 13.502-18.818 18.331l1.304 3.539c7.329-2.723 14.15-4.639 17.732-10.031 1.202-1.81 1.544-8.352 1.888-10.71z"
					clipRule="evenodd"
				></path>
				<path
					fill="#253858"
					fillRule="evenodd"
					d="M194.959 117.088c-6.525-.454-11.566-1.343-15.125-2.667-.441-.164-.379-.936-.077-1.414 4.706-7.443 8.197-14.046 9.18-19.62.55.41 1.599.642 3.146.696 3.044 6.458 3.747 13.377 2.876 23.005z"
					clipRule="evenodd"
				></path>
				<path
					fill="#DFE1E6"
					fillRule="evenodd"
					d="M193.176 93.78l-1.69-.299c-.8 2.235-7.544 33.217-13.177 42.747l20.742 3.658c1.199-9.43-.368-21.287-2.304-31.319 2.667 3.453 4.572 8.844 5.715 16.172l4.06-.307c-.704-12.896-5.038-23.048-13.002-30.454l-.034-.144-.15-.027a89.59 89.59 0 00-.147-.134l-.013.106z"
					clipRule="evenodd"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_8266_42680">
					<path fill="#fff" d="M0 0H214V164H0z"></path>
				</clipPath>
			</defs>
		</svg>
	)
}
