import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<"svg">

export const ElectronicIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 68 75"
            className={mergeClasses({ classes: ['w-20', className] })}
            {...props}
        >
            <path
                stroke="url(#paint0_linear_5569_40245)"
                strokeWidth="6"
                d="M6.829 21.757L34.085 6.02l27.257 15.737V53.23L34.085 68.967 6.83 53.23V21.757z"
            ></path>
            <circle
                cx="34.085"
                cy="37.493"
                r="6.817"
                fill="url(#paint1_linear_5569_40245)"
            ></circle>
            <circle
                cx="34.085"
                cy="6.817"
                r="6.817"
                fill="url(#paint2_linear_5569_40245)"
            ></circle>
            <circle
                cx="34.085"
                cy="68.17"
                r="6.817"
                fill="url(#paint3_linear_5569_40245)"
            ></circle>
            <circle
                cx="6.817"
                cy="21.303"
                r="6.817"
                fill="url(#paint4_linear_5569_40245)"
            ></circle>
            <circle
                cx="60.501"
                cy="21.303"
                r="6.817"
                fill="url(#paint5_linear_5569_40245)"
            ></circle>
            <circle
                cx="6.817"
                cy="52.832"
                r="6.817"
                fill="url(#paint6_linear_5569_40245)"
            ></circle>
            <circle
                cx="60.501"
                cy="52.832"
                r="6.817"
                fill="url(#paint7_linear_5569_40245)"
            ></circle>
            <defs>
                <linearGradient
                    id="paint0_linear_5569_40245"
                    x1="34.085"
                    x2="34.085"
                    y1="2.556"
                    y2="72.431"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1753E2"></stop>
                    <stop offset="1" stopColor="#DEE9FD" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5569_40245"
                    x1="34.085"
                    x2="34.085"
                    y1="30.677"
                    y2="44.311"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0.64"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5569_40245"
                    x1="34.085"
                    x2="34.085"
                    y1="0"
                    y2="13.634"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0.64"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5569_40245"
                    x1="34.085"
                    x2="34.085"
                    y1="61.353"
                    y2="74.987"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0.64"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_5569_40245"
                    x1="6.817"
                    x2="6.817"
                    y1="14.486"
                    y2="28.12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0.41"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_5569_40245"
                    x1="60.501"
                    x2="60.501"
                    y1="14.486"
                    y2="28.12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0.41"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_5569_40245"
                    x1="6.817"
                    x2="6.817"
                    y1="46.015"
                    y2="59.649"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0.41"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_5569_40245"
                    x1="60.501"
                    x2="60.501"
                    y1="46.015"
                    y2="59.649"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0.41"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
