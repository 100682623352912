import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<"svg">

export const TeamworkIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 374 389"
            className={mergeClasses({ classes: ['w-40', className] })}
            {...props}
        >
            <rect
                width="122.233"
                height="74.369"
                fill="#DFE1E6"
                rx="4"
                transform="matrix(.95106 -.30902 0 1 0 145.718)"
            ></rect>
            <rect
                width="31.243"
                height="69.588"
                fill="#fff"
                rx="4"
                transform="matrix(.95106 -.30902 0 1 2.085 147.166)"
            ></rect>
            <rect
                width="84.412"
                height="12.749"
                fill="#fff"
                rx="4"
                transform="matrix(.95106 -.30902 0 1 33.885 136.833)"
            ></rect>
            <rect
                width="83.864"
                height="54.715"
                fill="#fff"
                rx="4"
                transform="matrix(.95106 -.30902 0 1 34.406 151.538)"
            ></rect>
            <rect
                width="22.473"
                height="4.25"
                fill="#5F85EB"
                rx="1"
                transform="matrix(.95106 -.30902 0 1 90.185 122.79)"
            ></rect>
            <rect
                width="22.473"
                height="4.25"
                fill="#C1C7D0"
                rx="1"
                transform="matrix(.95106 -.30902 0 1 37.534 139.897)"
            ></rect>
            <rect
                width="22.473"
                height="1.594"
                fill="#3C67F0"
                rx="0.797"
                transform="matrix(.95106 -.30902 0 1 90.185 136.601)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 144.569)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 165.818)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 149.881)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 171.13)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 147.226)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 168.474)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 152.537)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 173.786)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 155.194)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 176.442)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 161.677)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 182.925)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 166.989)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 188.238)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 164.333)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 185.581)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 169.645)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 190.894)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 160.506)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 181.754)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 157.85)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 179.098)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 163.162)"
            ></rect>
            <rect
                width="22.473"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 90.185 184.41)"
            ></rect>
            <rect
                width="49.332"
                height="1.594"
                fill="#C1C7D0"
                rx="0.797"
                transform="matrix(.95106 -.30902 0 1 37.534 153.708)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 172.301)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 193.55)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 177.613)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 198.862)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 174.957)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 196.206)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 180.269)"
            ></rect>
            <rect
                width="49.332"
                height="1.062"
                fill="#C1C7D0"
                rx="0.531"
                transform="matrix(.95106 -.30902 0 1 37.534 201.518)"
            ></rect>
            <path
                fill="#EBECF0"
                d="M0 0H26.858V5.312H0z"
                transform="matrix(.95106 -.30902 0 1 4.17 208.108)"
            ></path>
            <path
                fill="#EBECF0"
                d="M0 0H26.858V5.312H0z"
                transform="matrix(.95106 -.30902 0 1 4.17 201.734)"
            ></path>
            <path
                fill="#EBECF0"
                d="M0 0H26.858V5.312H0z"
                transform="matrix(.95106 -.30902 0 1 4.17 195.359)"
            ></path>
            <path
                fill="#EBECF0"
                d="M0 0H26.858V5.312H0z"
                transform="matrix(.95106 -.30902 0 1 4.17 188.985)"
            ></path>
            <path
                fill="#EBECF0"
                d="M0 0H26.858V5.312H0z"
                transform="matrix(.95106 -.30902 0 1 4.17 182.61)"
            ></path>
            <path
                fill="url(#paint0_linear_5569_40272)"
                d="M124.554 137.528l124.554 41.887v108.097l-124.554-41.888V137.528z"
            ></path>
            <path
                fill="url(#paint1_linear_5569_40272)"
                d="M248.07 98.605l125.591 39.68-124.553 42.837-124.554-43.288L248.07 98.605z"
            ></path>
            <path
                fill="url(#paint2_linear_5569_40272)"
                d="M373.661 138.047l-124.554 42.66v107.324l124.554-41.888V138.047z"
            ></path>
            <path
                fill="url(#paint3_linear_5569_40272)"
                d="M212.779 14.069l34.772 15.151v101.732L212.779 115.8V14.069z"
            ></path>
            <path
                fill="url(#paint4_linear_5569_40272)"
                d="M282.841 14.069l-35.29 15.151v103.896l35.29-15.432V14.069z"
            ></path>
            <path
                fill="#1753E2"
                d="M282.841 14.07l-35.29 15.15-34.772-15.15L247.032 0l35.809 14.07z"
            ></path>
            <path
                fill="url(#paint5_linear_5569_40272)"
                d="M148.427 56.828l34.771 10.899v73.175l-34.771-10.899V56.828z"
            ></path>
            <path
                fill="url(#paint6_linear_5569_40272)"
                d="M218.488 56.828l-35.29 10.899v74.732l35.29-11.1v-74.53z"
            ></path>
            <path
                fill="#3A8356"
                d="M218.488 56.828l-35.29 10.898-34.771-10.898 34.252-10.12 35.809 10.12z"
            ></path>
            <path
                fill="url(#paint7_linear_5569_40272)"
                d="M272.461 63.589l34.771 7.56v50.767l-34.771-7.561V63.589z"
            ></path>
            <path
                fill="url(#paint8_linear_5569_40272)"
                d="M342.523 63.589l-35.291 7.56v51.848l35.291-7.701V63.589z"
            ></path>
            <path
                fill="#F16363"
                d="M342.523 63.59l-35.29 7.56-34.772-7.56 34.253-7.022 35.809 7.021z"
            ></path>
            <path
                fill="url(#paint9_linear_5569_40272)"
                d="M0 238.141l124.554 41.87v108.051L0 346.192V238.141z"
            ></path>
            <path
                fill="url(#paint10_linear_5569_40272)"
                d="M123.516 199.201l125.591 39.636-124.553 42.788L0 238.387l123.516-39.186z"
            ></path>
            <path
                fill="url(#paint11_linear_5569_40272)"
                d="M249.107 238.791l-124.553 42.642v107.278l124.553-41.869V238.791z"
            ></path>
            <path
                fill="#505F79"
                d="M150.96 245.734c-5.368-1.851-1.094-2.052 2.594-1.889.184-.229.973-.677 2.855-1.484 5.449-2.336 21.797 0 26.987 0 5.19 0 3.114.259 7.006 1.038 3.892.778-4.411 3.113-8.822 5.449-4.412 2.335-15.31-.26-19.721 0-4.412.259-3.374-.519-10.899-3.114zM228.089 164.255c-3.831-1.557-2.076-2.335 1.877-3.633-1.297.26-.528-1.245 3.832-3.114 5.449-2.335 3.632-2.076 8.822-2.076 5.19 0 18.164-4.67 22.056-3.892 3.893.779-2.335 4.411-6.746 6.747-4.411 2.335-7.266 2.076-11.677 2.335-4.411.26-10.79 6.63-18.164 3.633z"
            ></path>
            <path
                fill="#253858"
                d="M155.692 245.614l.778-5.708 4.152.778 1.038 1.038c.939.939 3.979 3.114 5.709 3.892.865.346 2.283 1.038 1.038 1.038-1.246 0-6.228-.346-8.563-.519l-1.038-1.297-1.298.778h-1.816zM172.04 244.057l.778-5.708 4.152.778 1.038 1.038c.939.939 3.979 3.114 5.709 3.892.865.346 2.283 1.038 1.038 1.038-1.246 0-6.228-.346-8.564-.519l-1.037-1.297-1.298.778h-1.816z"
            ></path>
            <path
                fill="#0B1730"
                d="M155.173 239.646l5.968-55.011c7.889-2.699 13.148-2.302 14.791-1.178.606 2.422 1.972 10.052 2.595 16.487.623 6.436-.26 28.803-.779 39.183-1.21.173-3.944.519-5.189.519-1.246 0-2.422-26.468-2.855-39.702-2.335 13.494-7.317 40.532-8.563 40.74-1.245.207-4.497-.606-5.968-1.038z"
            ></path>
            <path
                fill="#C1C7D0"
                d="M193.545 162.875c-.623 1.038-7.525 4.065-10.899 5.449.433 1.298.934 4.308-.519 5.968 10.639-3.114 12.196-8.822 14.791-9.86 2.595-1.038 3.373-3.893 1.557-4.93-1.817-1.038-3.114 2.854-3.373 2.335-.26-.519 1.037-2.335 0-1.816-1.038.519-.779 1.556-1.557 2.854z"
            ></path>
            <path
                fill="#305CBD"
                d="M182.127 174.255c1.246-.208 1.038-4.065.779-5.968-2.595-1.817-10.38-8.304-14.272-8.304-3.114 0-1.989 2.768-1.038 4.152 3.633 3.2 11.106 9.705 11.937 10.12 1.037.519 1.037.259 2.594 0zM225.083 129.625c-1.18.373-2.715-3.086-3.335-4.863 1.457-2.74 5.366-11.866 8.763-13.597 2.718-1.386 2.968 1.531 2.753 3.162-1.747 4.41-5.376 13.412-5.916 14.144-.676.915-.791.688-2.265 1.154z"
            ></path>
            <path
                fill="#C1C7D0"
                d="M210.053 124.771c1.006.629 8.378.201 11.938-.092.2 1.325 1.101 4.176 3.108 4.979-10.672 2.015-14.571-2.275-17.297-2.027-2.727.248-4.677-1.897-3.553-3.611 1.124-1.714 3.988 1.106 3.984.538-.005-.569-1.945-1.577-.808-1.586 1.136-.008 1.372 1.013 2.628 1.799z"
            ></path>
            <path
                fill="#1753E2"
                d="M171.261 158.686c-3.529.623-6.141.259-7.006 0-1.989.778-6.02 2.698-6.228 4.152-.259 1.816 2.336 14.79 2.855 17.126.519 2.335-.48 4.411.519 4.93 4.359 2.699 11.676.778 14.79-.519-.173-2.162-.415-6.902 0-8.563.415-1.661.173-7.785 0-10.639.173-.952.415-3.166 0-4.411-.519-1.557-.519-2.855-4.93-2.076zM227.641 111.201c3.357-1.026 5.476-2.505 6.115-3.117 2.083-.205 6.456-.322 7.284.854 1.034 1.47 4.541 13.949 5.127 16.219.586 2.269 2.382 3.637 1.741 4.534-2.605 4.295-9.846 5.875-13.142 6.127-.811-1.964-2.708-6.209-3.809-7.474-1.102-1.265-3.615-6.718-4.734-9.287-.574-.753-1.77-2.578-1.962-3.85-.24-1.59-.817-2.723 3.38-4.006z"
            ></path>
            <rect
                width="43.594"
                height="51.897"
                x="168.666"
                y="134.414"
                fill="#fff"
                rx="4"
                transform="rotate(-30 168.666 134.414)"
            ></rect>
            <rect
                width="39.961"
                height="2.595"
                x="171.053"
                y="135.433"
                fill="#DEE9FD"
                rx="1.297"
                transform="rotate(-30 171.053 135.433)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="173.388"
                y="139.478"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 173.388 139.478)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="174.426"
                y="141.276"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 174.426 141.276)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="175.464"
                y="143.073"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 175.464 143.073)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="176.502"
                y="144.871"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 176.502 144.871)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="177.54"
                y="146.669"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 177.54 146.669)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="178.578"
                y="148.467"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 178.578 148.467)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="191.552"
                y="170.939"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 191.552 170.939)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="192.59"
                y="172.737"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 192.59 172.737)"
            ></rect>
            <rect
                width="39.961"
                height="1.038"
                x="193.628"
                y="174.535"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 193.628 174.535)"
            ></rect>
            <rect
                width="34.771"
                height="1.038"
                x="194.666"
                y="176.333"
                fill="#EBECF0"
                rx="0.519"
                transform="rotate(-30 194.666 176.333)"
            ></rect>
            <path
                fill="#EBECF0"
                d="M186.178 153.068H217.576V153.476H186.178z"
                transform="rotate(-30 186.178 153.068)"
            ></path>
            <path
                fill="#EBECF0"
                d="M191.683 162.603H223.081V163.011H191.683z"
                transform="rotate(-30 191.683 162.603)"
            ></path>
            <path
                fill="#EBECF0"
                d="M188.828 157.659H220.226V158.06699999999998H188.828z"
                transform="rotate(-30 188.828 157.659)"
            ></path>
            <path
                fill="#EBECF0"
                d="M191.887 162.956H203.304V163.36399999999998H191.887z"
                transform="rotate(-120 191.887 162.956)"
            ></path>
            <path
                fill="#EBECF0"
                d="M198.596 159.082H210.013V159.48999999999998H198.596z"
                transform="rotate(-120 198.596 159.082)"
            ></path>
            <path
                fill="#EBECF0"
                d="M204.953 155.412H216.37V155.82H204.953z"
                transform="rotate(-120 204.953 155.412)"
            ></path>
            <path
                fill="#EBECF0"
                d="M209.543 152.762H220.96V153.17H209.543z"
                transform="rotate(-120 209.543 152.762)"
            ></path>
            <path
                fill="#EBECF0"
                d="M214.134 150.111H225.551V150.51899999999998H214.134z"
                transform="rotate(-120 214.134 150.111)"
            ></path>
            <path
                fill="#EBECF0"
                d="M218.725 147.46H230.142V147.868H218.725z"
                transform="rotate(-120 218.725 147.46)"
            ></path>
            <path
                fill="#C1C7D0"
                d="M173.337 171.66c.415-.83 0-2.249-.259-2.854 5.449-5.45 9.082-8.823 9.082-10.639 0-1.817 1.557-4.931 2.076-4.412.351.352.355.977.296 1.37.158-.646.722-1.701 2.039-1.889 1.816-.259 1.557 2.076 1.557 2.855 0 .778-.779 2.335-1.817 3.373-1.038 1.038-.519.259-1.038 1.038-.519.778-4.151 7.785-5.449 10.12-1.297 2.335-4.93 5.19-6.746 5.449-1.817.26-1.817-.259-2.076-1.038-.26-.778 1.816-2.335 2.335-3.373z"
            ></path>
            <path
                fill="url(#paint12_linear_5569_40272)"
                d="M159.68 164.094c.185-2.016 9.077 2.038 13.398 4.712 1.037 2.594 0 3.144 0 3.373 0 .229-1.363 1.311-1.595 1.769-.231.459-.231 1.604-.463 1.604-.231 0-10.877-6.645-11.108-7.104-.232-.458-.463-1.833-.232-4.354z"
            ></path>
            <path
                fill="#C1C7D0"
                d="M231.713 122.994c-.639-.576-1.038-.778-1.817-.519-5.514 5.158-10.046 9.612-11.824 9.552-1.779-.059-4.879 1.364-4.387 1.889.333.356.945.38 1.331.335-.638.134-1.689.652-1.916 1.935-.313 1.77 1.982 1.592 2.744 1.617.762.026 2.312-.686 3.363-1.668 1.05-.982.271-.5 1.05-.982.779-.483 7.758-3.812 10.087-5.006 2.329-1.194 5.243-4.658 5.556-6.428.313-1.77-.195-1.787-.948-2.067-.754-.279-2.395 2.103-3.239 1.342z"
            ></path>
            <path
                fill="url(#paint13_linear_5569_40272)"
                d="M240.156 110.771c-1.059-1.678-7.017 5.817-9.599 10.073l-.661 1.635c.208-.208 2.98 1.343 4.341 2.144.406.298.915 1.298 1.118 1.195.202-.103 6.538-10.64 6.536-11.143-.002-.503-.412-1.806-1.735-3.904z"
            ></path>
            <path
                fill="#C1C7D0"
                d="M164.255 158.686l-.519-5.19 5.19-6.746 4.93 2.595c0 3.113 0 3.113-.26 4.151-.112.451-.951 2.595-1.297 3.633-.519-.086-1.557-.104-1.557.519 0 .779.779 1.038.519 1.557-.259.519-2.335 1.298-3.892 1.298-1.246 0-2.595-1.211-3.114-1.817z"
            ></path>
            <path
                fill="#253858"
                d="M163.476 153.496l-.518-4.151c.518-4.671 3.632-5.45 6.746-4.931 2.491.416 3.806 3.46 4.152 4.931-.778-.433-2.699-1.35-4.152-1.557-1.816-.26-1.038 2.076-1.038 2.595 0 .519-.259 1.556-1.557 1.556-1.297 0-.519-.259-1.038 0-.519.26-.259 1.298-.778 2.076-.415.623-1.384-.086-1.817-.519z"
            ></path>
            <path
                fill="#0B1730"
                d="M248.32 152.579c6.487-18.943 2.595-20.413-.26-23.354l-6.746 3.114-.519 5.449c-2.699 6.435.779 16.002 2.854 19.981l18.424-4.671-.779-5.709-12.974 5.19z"
            ></path>
            <path
                fill="#1D1D1D"
                d="M262.592 147.649l-1.298.519v2.854l1.298 1.557-.519 1.816 1.557-1.297-1.038-5.449zM230.155 162.841l5.851-7.134 5.527-2.113 2.037 5.775-1.144.194-.615-1.544-1.101 1.835-10.286 3.883-.269-.896z"
            ></path>
            <path
                fill="#253858"
                d="M226.783 137.268c8.096-1.453 17.386-6.141 21.018-8.303 1.73 1.297 5.657 6.435 3.374 9.342-2.076 3.321-9.861 4.93-17.126 4.67l8.303 10.639c-2.076 1.298-6.228 4.515-6.228 3.893 0-.779-4.93-6.228-9.341-8.563-4.411-2.336-10.12-9.861 0-11.678z"
            ></path>
            <path
                fill="#C1C7D0"
                d="M233.756 108.084l-1.856-4.761-7.531-3.58-3.149 4.458c1.385 2.718 1.385 2.718 2.073 3.509.299.343 1.985 1.842 2.749 2.594.415-.307 1.313-.783 1.59-.24.346.68-.218 1.253.24 1.59.457.338 2.615.094 3.974-.599 1.088-.554 1.727-2.212 1.91-2.971z"
            ></path>
            <path
                fill="#253858"
                d="M232.127 103.208l-1.394-3.855c-2.531-3.846-5.595-3.14-8.083-1.301-1.989 1.47-1.782 4.713-1.43 6.15.487-.724 1.755-2.378 2.931-3.206 1.47-1.035 1.83 1.35 2.061 1.803.231.453.919 1.244 2.051.667 1.133-.578.338-.458.906-.462.569-.005.804 1.017 1.603 1.466.64.358 1.17-.692 1.355-1.262z"
            ></path>
            <circle
                cx="229.222"
                cy="96.296"
                r="3.114"
                fill="#253858"
                transform="rotate(-27.007 229.222 96.296)"
            ></circle>
            <path
                fill="#C1C7D0"
                fillRule="evenodd"
                d="M88.654 143.939c1.072-1.259 1.798-2.801 1.677-4.529-.35-4.98-7.282-3.885-8.63-1.371-1.349 2.513-1.188 8.889.554 9.338.695.179 2.176-.26 3.683-1.168l-.946 6.691h5.585l-1.923-8.961z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D1D1D"
                fillRule="evenodd"
                d="M85.659 139.959c-.214 2.978-.78 4.467-1.697 4.467-1.376 0-2.543-.873-3.147-.873-.44 0-.342.551-.05.959.24.337 1.413.661 1.413 1.184 0 .522-.926.118-.926.428 0 .512-.163 1.475.726 1.475.9 0 3.48-.328 3.923-1.663.285-.862.47-2.649.553-5.359a1.396 1.396 0 011.142 2.385c.324.456.686.932 1.1 1.424 1.56-.935 2.716-3.673 1.192-6.302-.421-1.289-1.375-1.977-2.5-2.296-.913-.382-1.86-.425-2.66-.196-1.566.134-2.966.612-3.362.896 0 1.483.287 1.924 2.742 1.885.604.318 1.067.867 1.55 1.586z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0B1730"
                fillRule="evenodd"
                d="M92.438 172.215L84 201.821l-6.155 21.821h-4.49l6.541-51.427h12.54z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#253858"
                fillRule="evenodd"
                d="M94.694 172.215c.195 15.259.86 23.582 1.065 24.968.205 1.387 3.303 10.206 9.294 26.459h-4.651c-7.627-15.592-11.884-24.411-12.773-26.459-.888-2.047-3.629-10.37-7.757-24.968h14.822z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D1D1D"
                fillRule="evenodd"
                d="M105.469 227.83l-.232-4.886h-4.887c-2.317 1.629-5.343 2.947-9.076 3.956v.93h8.843l3.025-.465v.465h2.327zM78.476 227.83l-.233-4.886h-4.887c-2.317 1.629-5.342 2.947-9.075 3.956v.93h8.842l3.026-.465v.465h2.327z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#C1C7D0"
                fillRule="evenodd"
                d="M72.808 168.401l-7.025 5.956c-1.97.682-3.6 1.474-4.892 2.374-.298.313-.641.927.672.733 1.312-.194 2.702-.339 2.916.099.213.437-.548 1.032-.137 1.614.274.387 1.362-.709 3.266-3.29l7.12-3.554-1.92-3.932zm31.808-2.577l-4.121.599c4.424 9.599 6.796 14.675 7.115 15.229.719 1.247-.126 3.418-.457 4.567 1.671.483 1.137-2.098 3.249-1.454 1.928.587 3.519 1.919 5.428.335.235-.195.433-.831-.313-1.202-1.859-.925-4.588-2.616-5.013-3.239-.578-.85-2.541-5.795-5.888-14.835z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#EBECF0"
                fillRule="evenodd"
                d="M87.39 149.022l-2.554-.845c-3.465 8.655-8.631 17.028-17.264 24.101l2.135 3.683c7.587-4.469 14.769-7.941 17.683-14.647.978-2.251.08-9.601 0-12.292z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#3C67F0"
                fillRule="evenodd"
                d="M96.162 173.379c-7.316.775-13.075.775-17.275 0-.52-.096-.603-.968-.362-1.559 3.76-9.21 6.337-17.246 6.337-23.642.69.347 1.896.4 3.62.156 4.634 6.592 6.764 14.156 7.68 25.045z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#EBECF0"
                fillRule="evenodd"
                d="M89.634 147.781h-1.93c-.45 2.645-1.869 38.457-6.247 50.172h23.69c-.513-10.732-4.565-23.623-8.669-34.411 3.629 3.321 6.792 8.948 9.489 16.881l4.438-1.139c-3.3-14.217-10.083-24.667-20.351-31.349l-.066-.154h-.171l-.19-.12.007.12z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_5569_40272"
                    x1="211.741"
                    x2="147.654"
                    y1="145.635"
                    y2="266.787"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1B55CE"></stop>
                    <stop offset="1" stopColor="#CCDBFB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5569_40272"
                    x1="249.108"
                    x2="249.108"
                    y1="117.543"
                    y2="181.122"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6BFF7"></stop>
                    <stop offset="1" stopColor="#CCDBFB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5569_40272"
                    x1="306.195"
                    x2="383.152"
                    y1="141.64"
                    y2="258.554"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6BFF7"></stop>
                    <stop offset="1" stopColor="#F3F6FA" stopOpacity="0.1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5569_40272"
                    x1="230.165"
                    x2="230.165"
                    y1="14.069"
                    y2="130.952"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1753E2"></stop>
                    <stop offset="0.792" stopColor="#F3F6FA" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_5569_40272"
                    x1="265.196"
                    x2="265.196"
                    y1="14.069"
                    y2="133.116"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6BFF7"></stop>
                    <stop offset="0.875" stopColor="#DEE9FD" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_5569_40272"
                    x1="165.812"
                    x2="165.812"
                    y1="56.828"
                    y2="140.902"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="0.792" stopColor="#AADABD" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_5569_40272"
                    x1="200.843"
                    x2="200.843"
                    y1="56.828"
                    y2="142.459"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="0.625" stopColor="#AADABD" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_5569_40272"
                    x1="289.847"
                    x2="289.847"
                    y1="63.589"
                    y2="121.916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F68585"></stop>
                    <stop offset="0.792" stopColor="#F68585" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear_5569_40272"
                    x1="324.877"
                    x2="324.877"
                    y1="63.589"
                    y2="122.997"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F9B4B4"></stop>
                    <stop offset="0.875" stopColor="#FCDEDE" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint9_linear_5569_40272"
                    x1="87.188"
                    x2="23.142"
                    y1="246.245"
                    y2="367.369"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3C67F0"></stop>
                    <stop offset="1" stopColor="#F3F6FA" stopOpacity="0.11"></stop>
                </linearGradient>
                <linearGradient
                    id="paint10_linear_5569_40272"
                    x1="124.554"
                    x2="124.554"
                    y1="218.118"
                    y2="281.625"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6BFF7"></stop>
                    <stop offset="1" stopColor="#CCDBFB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint11_linear_5569_40272"
                    x1="221.342"
                    x2="241.412"
                    y1="290.711"
                    y2="421.056"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A6BFF7"></stop>
                    <stop offset="0.661" stopColor="#F3F6FA" stopOpacity="0.1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint12_linear_5569_40272"
                    x1="159.584"
                    x2="172.299"
                    y1="166.071"
                    y2="172.559"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1753E2"></stop>
                    <stop offset="1" stopColor="#3C67F0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint13_linear_5569_40272"
                    x1="241.119"
                    x2="232.906"
                    y1="112.454"
                    y2="123.773"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1753E2"></stop>
                    <stop offset="1" stopColor="#3C67F0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
