import React, { Dispatch, SetStateAction } from "react"
import {
  Container,
  PrimaryBtn,
} from "components/library"
import { ConnectCycleIllustration } from "components/library/illustrations/connect-cycle-illustration";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
// import { formatTimeValue } from "utils"
import { CONNECT_BASE_URL, CONNECT_CLOSES_IN } from "variables"
import { Link } from "gatsby"
import { ConnectCycleIllustrationJp } from "components/library/illustrations/connect-cycle-illustration-jp";

type P = {
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const Footer = ({ setModalOpen }: P) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  // const today = new Date()
  // const difference = CONNECT_CLOSES_IN.getTime() - today.getTime()
  // const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  // const hours = Math.floor(
  // 	(difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // )
  // const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))

  return (
    <section className="bg-no-repeat bg-cta-bg bg-cover w-full">
      <Container className="flex flex-col-reverse md:flex-row items-center pb-20 justify-between pt-40 text-white gap-16 md:gap-12 select-none">
        <div className="flex flex-col items-right text-left gap-8">
          <h2 className="text-lg sm:text-2xl lg:text-5xl">
            {t("common.messages.discover-connect")}
          </h2>

          {/* <div className="flex h-20 sm:h-24 lg:h-28 w-full sm:w-80 lg:w-96 border-white border-2 rounded-md">
            <div className="flex uppercase flex-col w-full items-center justify-center">
              <span className="text-sm font-medium">
                {t("common.words.days")}
              </span>
              <span className="text-4xl md:text-5xl font-semibold">
                {formatTimeValue(days)}
              </span>
            </div>
            <div className="h-full w-1 bg-white"></div>
            <div className="flex flex-col w-full items-center justify-center">
              <span className="text-sm font-medium">
                {t("common.words.hours")}
              </span>
              <span className="text-4xl md:text-5xl font-semibold">
                {formatTimeValue(hours)}
              </span>
            </div>
            <div className="h-full w-1 bg-white"></div>
            <div className="flex flex-col w-full items-center justify-center">
              <span className="text-sm font-medium">
                {t("common.words.minutes")}
              </span>
              <span className="text-4xl md:text-5xl font-semibold">
                {formatTimeValue(minutes)}
              </span>
            </div>
          </div> */}
          <p className="sm:text-lg lg:text-xl">
            {t("home-page.designed-by.connect-card.desc")}
          </p>

          <Link
            to={language === "ja" ? null : CONNECT_BASE_URL}
            target="_blank"
          >
            <PrimaryBtn
              onClick={() => language === "ja" && setModalOpen(true)}
              className="md:text-lg w-full sm:max-w-max"
            >
              {t("common.cta.getStarted")}
            </PrimaryBtn>
          </Link>
        </div>

        <div className="md:w-[50%] w-[80%] relative">
          {language === "ja" ? (
            <ConnectCycleIllustrationJp />
          ) : (
            <ConnectCycleIllustration />
          )}

          <div className="absolute left-1/2 transform -bottom-3 sm:-bottom-6 -translate-x-1/2 w-[65%] z-10">
            <StaticImage
              src="../../../images/product-on-laptop.png"
              className="w-full"
              alt="connect"
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
