import React, { useEffect, useState } from "react"
import { Container } from "components/library"
import { graphql } from "gatsby"
import { ExploreBenefits } from "components/pages/connect/explore-benefits/explore-benefits"
import { Diversity } from "components/pages/connect/diversity"
import { FeatureCapabilities } from "components/pages/connect/feature-capabilities/feature-capabilities"
import { EnquiryRegisterForm } from "components/pages/connect/form/enquiry-register-form"
import { Footer } from "components/pages/connect/footer"
import { Hero } from "components/pages/connect/connect-hero"

const Connect = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	useEffect(() => {
		if (location.search === "?subscribe") setIsModalOpen(true)
	}, [location.search])

	return (
		<main className="overflow-hidden">
			<Container className="flex flex-col gap-20 sm:gap-28 pt-12 sm:pt-20 sm:pb-36">
				<Hero setModalOpen={setIsModalOpen} />
				<ExploreBenefits />
				<div className="flex flex-col gap-12 sm:gap-16">
					<Diversity />
					<FeatureCapabilities />
				</div>
			</Container>

			<EnquiryRegisterForm open={isModalOpen} setOpen={setIsModalOpen} />

			<Footer setModalOpen={setIsModalOpen} />
		</main>
	)
}

export default Connect

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
