import React from "react";
import { Illustration } from 'types/components';
import { TranslationKey } from 'types/translation';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

type P = {
    title: TranslationKey
    description: TranslationKey
    icon: Illustration
};

export type FeatureCapabilitiesCardProps = P

export const FeatureCapabilitiesCard = ({ description, icon, title }: P) => {
    const Icon = icon
    const { t } = useTranslation()

    return (
        <div className="flex flex-col gap-4 max-w-sm">
            <div className='flex flex-col gap-2'>
                <Icon />
                <span className='text-3xl'>{t(title) as string}</span>
            </div>

            <span>
                <Trans
                    components={{
                        anchor: <a className="anchor" />,
                    }}
                    i18nKey={(description) as string}
                />
            </span>
        </div>
    )
};
