import React from "react"
import Image from "images/illus_eliminate_silos.png"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

export const Content = () => {
	const { t } = useTranslation()

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
			<div className="flex flex-col gap-4">
				<span className="uppercase text-sm sm:text-lg lg:text-xl bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent max-w-max">
					{t("connect-page.explore-benefits.content.label")}
				</span>

				<h2 className="text-[40px] md:text-5xl leading-tight sm:leading-normal max-w-3xl">
					{t("connect-page.explore-benefits.content.heading")}
				</h2>

				<p className="text-lg md:text-2xl max-w-2xl">
					<Trans
						components={{
							anchor: <a className="anchor" />,
							bold: <span className="text-theme-orange" />,
						}}
						i18nKey="connect-page.explore-benefits.content.desc"
					/>
				</p>
			</div>

			<div className="sm:p-14 p-10">
				<img
					src={Image}
					alt="Centralise your data, collaborate like you’re decentralised"
				/>
			</div>
		</div>
	)
}
