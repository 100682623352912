import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils';

type P = ComponentPropsWithoutRef<"svg">

export const TeamIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 71 56"
            className={mergeClasses({ classes: ['w-20', className] })}
            {...props}
        >
            <path
                fill="url(#paint0_linear_5569_40254)"
                fillRule="evenodd"
                d="M21.159 25.316c0-5.019 5.812-9.088 12.983-9.088 7.17 0 12.982 4.07 12.982 9.088H21.16zm12.983-12.333a6.491 6.491 0 100-12.983 6.491 6.491 0 000 12.983z"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint1_linear_5569_40254)"
                d="M55.38 26.875a7.797 7.797 0 100-15.594 7.797 7.797 0 000 15.594z"
            ></path>
            <path
                fill="url(#paint2_linear_5569_40254)"
                d="M39.785 41.689c0-6.029 6.982-10.916 15.594-10.916 8.613 0 15.595 4.887 15.595 10.916H39.785z"
            ></path>
            <path
                fill="url(#paint3_linear_5569_40254)"
                d="M15.594 26.875a7.797 7.797 0 100-15.594 7.797 7.797 0 000 15.594z"
            ></path>
            <path
                fill="url(#paint4_linear_5569_40254)"
                d="M0 41.689c0-6.029 6.982-10.916 15.594-10.916 8.612 0 15.594 4.887 15.594 10.916H0z"
            ></path>
            <path
                fill="url(#paint5_linear_5569_40254)"
                fillRule="evenodd"
                d="M14.557 56c0-7.572 8.768-13.71 19.584-13.71 10.817 0 19.585 6.139 19.585 13.71h-39.17zm19.585-18.606c5.408 0 9.792-4.384 9.792-9.792 0-5.408-4.384-9.793-9.792-9.793-5.409 0-9.793 4.385-9.793 9.793 0 5.408 4.384 9.792 9.793 9.792z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_5569_40254"
                    x1="34.612"
                    x2="34.612"
                    y1="16.172"
                    y2="29.166"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F16363"></stop>
                    <stop offset="1" stopColor="#FCDEDE" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5569_40254"
                    x1="55.379"
                    x2="55.379"
                    y1="30.773"
                    y2="41.689"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5569_40254"
                    x1="55.379"
                    x2="55.379"
                    y1="30.773"
                    y2="41.689"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7BC598"></stop>
                    <stop offset="1" stopColor="#9CF1BE" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5569_40254"
                    x1="15.594"
                    x2="15.594"
                    y1="30.773"
                    y2="41.689"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_5569_40254"
                    x1="15.594"
                    x2="15.594"
                    y1="30.773"
                    y2="41.689"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F39244"></stop>
                    <stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_5569_40254"
                    x1="34.851"
                    x2="34.85"
                    y1="42.206"
                    y2="61.808"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1753E2"></stop>
                    <stop offset="1" stopColor="#DEE9FD" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
