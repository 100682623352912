import React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { TeamworkIllustration } from "components/library/illustrations/teamwork-illustration";
import { MoleculesIllustration } from "components/library/illustrations/molecules-illustration";
import { ElectronicIllustration } from "components/library/illustrations/feature-capabilities/electronic-illustration";
import {
    DataIngestionIllustration
} from "components/library/illustrations/feature-capabilities/data-ingestion-illustration";
import { TeamIllustration } from "components/library/illustrations/feature-capabilities/team-illustration";
import {
    DataSecurityIllustration
} from "components/library/illustrations/feature-capabilities/data-security-illustration";
import { AnalyticsIllustration } from "components/library/illustrations/feature-capabilities/analytics-illustration";
import { FeatureCapabilitiesCard, FeatureCapabilitiesCardProps } from "./feature-capabilities-card";

const features: FeatureCapabilitiesCardProps[] = [
    {
        title: "connect-page.feature-capabilities.features.1.title",
        description: "connect-page.feature-capabilities.features.1.description",
        icon: ElectronicIllustration
    },
    {
        title: "connect-page.feature-capabilities.features.2.title",
        description: "connect-page.feature-capabilities.features.2.description",
        icon: DataIngestionIllustration
    },
    {
        title: "connect-page.feature-capabilities.features.3.title",
        description: "connect-page.feature-capabilities.features.3.description",
        icon: TeamIllustration
    },
    {
        title: "connect-page.feature-capabilities.features.4.title",
        description: "connect-page.feature-capabilities.features.4.description",
        icon: DataSecurityIllustration
    },
    {
        title: "connect-page.feature-capabilities.features.5.title",
        description: "connect-page.feature-capabilities.features.5.description",
        icon: AnalyticsIllustration
    },
]

export const FeatureCapabilities = () => {
    const { t } = useTranslation()

    return (
        <section className='flex flex-col gap-16 relative'>
            <MoleculesIllustration className='hidden sm:block absolute -right-52 -bottom-20 w-[480px] z-[-1] transform rotate-180' />

            <div className='flex justify-between items-center gap-10'>
                <div className='flex flex-col gap-4 sm:gap-8'>

                    <span className='uppercase text-sm sm:text-lg lg:text-xl bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent max-w-max'>
                        {t('connect-page.feature-capabilities.label')}
                    </span>

                    <h2 className='text-[40px] md:text-5xl leading-tight sm:leading-normal max-w-3xl'>
                        {t('connect-page.feature-capabilities.heading')}
                    </h2>

                    <p className='text-lg md:text-2xl max-w-2xl'>
                        <Trans
                            i18nKey="connect-page.feature-capabilities.desc"
                            components={{
                                bold: <span className="text-theme-orange" />,
                            }}
                        />
                    </p>
                </div>

                <TeamworkIllustration className='hidden sm:block h-[400px] w-auto' />
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-3 gap-12'>
                {features.map(feature => {
                    return <FeatureCapabilitiesCard key={feature.title} {...feature} />
                })}
            </div>
        </section>
    )
};
