import React from "react";
import SRC from 'images/icon_how_it_works.png'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export const Diversity = () => {
    const { t } = useTranslation()

    return (
        <div className='flex flex-col items-center gap-6 text-center'>
            <img
                src={SRC}
                alt="designed for diversity"
                className="w-20 sm:w-24"
            />
            <p className="tracking-wider text-gray-500 text-lg sm:text-2xl gradient-text uppercase">
                {t("connect-page.diversity.title")}
            </p>
            <p className='text-lg sm:text-2xl max-w-4xl'>
                <Trans
					i18nKey="connect-page.diversity.desc"
					components={{
						bold: <span className="text-theme-orange" />,
					}}
				/>
            </p>
        </div>
    )
};
